<template>
  <div  class="ma-md-5 mb-16 px-0 " >

    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/menu" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="text-h6 font-weight-bold my-6">会员等级</div>
    </div>

    <v-container fluid class="pa-1 px-md-0 content-body">
      <v-row>
        <v-col cols="12" >
          
          <v-img :src="vipbg" class="align-end" :class="{'white--text':!isVip,'black--text':isVip}">
           
            <div class="top-subtitle text-subtitle-1 pl-4 mb-1 mb-md-3">{{username}}</div>
          </v-img>
        </v-col>

        <v-col cols="12" >
          <v-card class="pt-10 pb-6  text-center" till flat>
            
            <div class="text-h5 font-weight-black">VIP会员权益</div>
            <div class="text-body-2 font-weight-black">{{vipSubTitle}}</div>
           
          </v-card>
        </v-col>

        <v-col cols="12" class="pa-0 ma-0 blackborder">
          
            
          <v-img class="card_item" :src="vipdesc"></v-img>
          
          <div class="d-flex flex-column align-center" v-if="!isVip">
            
            <div class="text-center text-h6 font-weight-bold mb-2"><span class="text-h4">128</span>元/年</div>
            <div class="" v-if="paytype">
              <div class="text-h6 font-weight-bold pa-2 under-line text-center">您的支付方式</div>
                <v-container class="pa-4">
                  <v-item-group
                    v-model="selected_pay"
                  >
                    <v-row>
                      <v-col
                        cols="6"
                        v-if="!inWeixinNow"
                      >
                        <v-item  v-slot="{ active,toggle }" value=2>
                          <div class="paytype pa-2" :class={payactive:active}>
                          <v-img
                            src="../assets/alipay3.png"
                            height="50"
                            width="140"
                            
                            
                            @click="toggle"
                          >
                          </v-img>
                          </div>
                        </v-item>
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <v-item v-slot="{ active,toggle }" value=1>
                          <div class="paytype px-1 py-2" :class={payactive:active}>
                          <v-img
                            src="../assets/WePayLogo.png"
                            height="40"
                            width="144"
                            
                            @click="toggle"
                          >
                          </v-img>
                          </div>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-item-group>
                </v-container>
            </div>
            <v-btn  dark outlined width="60%" tile class="main-btn" @click="buyVip">购买并解锁VIP权益</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>   


    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="text-center justify-center">
          <v-img
                src="../assets/WePayLogo.png"
                height="40"
                max-width="148"
              >
              </v-img>
          
        </v-card-title>
        <v-card-text>
          <v-img :src="weixincode" height="252" width="252" >
          </v-img>
          
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import orderApi from '@/api/order'
import { mapState } from 'vuex'
import vipbgimg from '@/assets/vip_member_bg.png'
import commonbgimg from '@/assets/common_member_bg2.png'

import viplocked from '@/assets/vip_locked.png'
import vipunlocked from '@/assets/vip_unlocked.png'

import {payWakeUp} from '@/utils/pay';

export default {


  data: () => ({
    itemid : 0,
    paying:false,
    //isVip : true,
    inWeixinNow:false,
    
    info : {},
    dialog : false,
    weixincode:"",
    paytype: false,
    selected_pay:"1"

  
    //
  }),
  computed: {

    ...mapState({
        apidomain: state => state.app.apidomain,
        domain: state => state.app.domain,
        username: state => state.user.user_name,
        userlevel: state => state.user.member_level,
      }),
    vipbg : function() {
      return this.isVip?vipbgimg:commonbgimg
    },
    vipdesc : function() {
      return this.isVip?vipunlocked:viplocked
    },
    isVip : function() {
      return this.userlevel==3
    },
    vipSubTitle:function() {
      return this.isVip?"您已加入VIP会员，尽享专属礼遇":"即刻加入VIP会员 解锁专属礼遇"
    },
    userlevelTitle : function() {
      return  this.isVip?"VIP会员":"普通会员"
    }
      
  },
  created() {
    
    this.inWeixinNow = this.$util.inWeiXin()
    console.log(this.userlevel)
    
  },
  methods: {
    homesearch() {
     
    },
    buyVip() {
      if (this.paytype===false) {
        this.paytype = true
        return
      }
      this.createVip();
    },
    createVip() {

      if (this.paying) return
      this.paying = true
      
      let params = {pay_type: this.selected_pay}
      orderApi.createvip(params).then(res => {
        let params2 = {out_trade_no:res.data.out_trade_no}
        orderApi.orderinfo(params2).then(res => {
          
          this.pay(res.data.order)
          
        })

        
        
      })

    },
    pay(info) {
      let paytype = info.payment_type==1?"NsWeixinpay":"NsAlipay"
      let pays = {type:paytype,out_trade_no:info.out_trade_no}

      if (this.$util.inWeiXin()) {

        let weixin_code = this.$route.query.code
        if (weixin_code) {
          pays.code = weixin_code
          //this.pay2(pays)
          payWakeUp({payparams:pays,qrcodeCallBack:(code_url) => {
            this.dialog = true
            this.weixincode = this.apidomain + "qrcode.php?code=" + encodeURIComponent(code_url)
          }})
        } else {
          const token = this.$util.getToken()
          location.href = this.domain + "api.php?action=onlinepay&token="+token+"&type="+paytype+"&out_trade_no="+info.out_trade_no
          return
        }
        
      }
      else {
        payWakeUp({payparams:pays,qrcodeCallBack:(code_url) => {
          this.dialog = true
          this.weixincode = this.apidomain + "qrcode.php?code=" + encodeURIComponent(code_url)
        }})
        //this.pay2(pays)
      }
      this.paying = false
      
      
    },
  },
};
</script>
<style scoped>

.content-body{
   width:684px;
}
.item-header {
  background-color: black;
  color:#ffffff;
  
  width:100%;
}


.card_title{
  height:80%;
}
.card_bottom{
  height:15%;
}
.vip-content{
  height:90%;
}
.vip-icon{
  width:120px;
  height:120px;
  border-radius:50%;
  padding-left:27px;
  padding-top:20px;
  background-color:#eeeeee;

}
.icon2 {
  margin-left:12px;
}

.darrow{
 
 width: 0; 
 height: 0;
 border-width: 30px;
 border-style: solid;
 opacity:0.7;
 border-radius:0px;
 border-color:#939393 transparent transparent transparent;
}
.blackborder {
  border:1px solid black;
}
.paytype {
  border:1px solid #ffffff;
  width:165px;
  height: 70px;
  cursor: pointer;
}
.payactive{
  border:1px solid black !important;
}
</style>

